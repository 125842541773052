import React from 'react';

import { Box } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { Trans, useTranslation } from 'next-i18next';

import { RippleBodyText02, RippleHyperLink } from '@/design';
import { lookupPinCode } from '@/services/pin';

import { PinCodeTicket } from './PinCodeTicket';

/**
 * ref: https://github.com/mckeever02/mailtolink/blob/master/src/scripts/index.js#L62
 */
function encodeMailString(content: string): string {
  return encodeURIComponent(content).replace(/%0A/g, '%0D%0A');
}

const mailContent = {
  subject: encodeMailString('Report abuse of Splashtop products'),
  body: encodeMailString(`Your privacy and security are important to us. If you suspect someone is using Splashtop maliciously, please let us know. We appreciate any information you can provide to help us shut down abuse.

Name:
Email:
Phone (optional):
PIN code (if available):
Description of abuse:`),
};

const mailToLink = `mailto:reportabuse@splashtop.com?subject=${mailContent.subject}&body=${mailContent.body}`;

export function PinCode(): React.JSX.Element {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'he';

  const lookupPinCodeMutate = useMutation(lookupPinCode, {
    onSuccess: ({ url }) => {
      location.href = url;
    },
  });

  function handleSubmit(pinCode: string): void {
    lookupPinCodeMutate.mutateAsync({ pinCode });
  }

  return (
    <Box style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
      <PinCodeTicket isError={lookupPinCodeMutate.isError} isLoading={lookupPinCodeMutate.isLoading} onSubmit={handleSubmit} />
      <Box mt="32px" mb="36px" mx="5px">
        <RippleBodyText02 textAlign="center">
          <Trans
            t={t}
            i18nKey="pinCode:reportAbuseHint"
            defaults="Only accept support from someone you trust. <hyperLink>Report abuse.</hyperLink>"
            components={{
              hyperLink: <RippleHyperLink variant="hyperlink02" href={mailToLink} target="_blank" rel="noopener noreferrer" />,
            }}
          />
        </RippleBodyText02>
        <RippleBodyText02 mt="12px" textAlign="center">
          <RippleHyperLink variant="hyperlink02" target="_blank" rel="noopener noreferrer" href="https://www.splashtop.com/enterprise">
            {t('pinCode:learnMore', 'Learn more about Splashtop.')}
          </RippleHyperLink>
        </RippleBodyText02>
      </Box>
    </Box>
  );
}
